<script>
import TransitionToggle from "../transitions/toggle.vue";
import SimpleTable from "../elements/simple-table.vue";
import {mapGetters} from "vuex";
import Swal from "sweetalert2";
import SpecializationForm from "../forms/specialization-form.vue";
export default {
    name: "specialization-manager",


    components: {SpecializationForm, SimpleTable, TransitionToggle},

    props: {
        positionId: {
            type: [String, Number]
        }
    },

    computed: {
        ...mapGetters({
            loading: 'specialization/loading',
            items: 'specialization/all',
            locale: 'trans/locale'
        }),

        fields: function () {
            return [
                {
                    key: 'name',
                    label: this.$t('organizations::specializations.columns.name').ucFirst(),
                    formatter: (value, index, item) => item.translation_key ? this.$t(item.translation_key).ucFirst() : value
                },
                {
                    key: 'icon',
                    label: this.$t('organizations::specializations.columns.icon').ucFirst()
                },
                {
                    key: 'color',
                    label: this.$t('organizations::specializations.columns.color').ucFirst()
                },
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    tdClass: 'text-end'
                }
            ];
        },
    },

    data: function () {
        return {
            showForm: false,
            item: {
                position_id: this.positionId
            },
            showModal: false,
            title: '',
            subtitle: '',
            content: ''
        }
    },

    methods: {
        readDescription: function (item) {
            this.title = item.translation_key ? this.$t(item.translation_key).ucFirst() : item.name
            this.subtitle = null
            this.content = item.description_translation_key ? this.$t(item.description_translation_key).ucFirst() : item.description
            this.showModal = true
        },

        editTableItem: function (item, callback) {
            this.$store.dispatch('specialization/setItem', item).then(() => {
                callback(item)
            })
        },

        removeTableItem: function (item) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.delete_confirm_title'),
                text: this.$t('base.delete_confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.delete').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('specialization/destroy', item.id)
                }
            });
        },

        updateTableItem: function (item, callback) {
            this.$store.dispatch('specialization/update', item).then(() => {
                this.$store.dispatch('specialization/clearItem')
                callback()
            })
        },

        add: function (item) {
            this.$store.dispatch('specialization/create', item).then(() => {
                this.$store.dispatch('specialization/clearItem')
                this.showForm = false
            })
        },

        clearItem: function () {
            this.item = {
                position_id: this.positionId
            }
            this.$store.dispatch('specialization/clearItem')
        }
    },

    created() {
        this.$store.dispatch('specialization/all', {position_id: this.positionId})
    }
}
</script>

<template>
    <b-overlay :show="loading">
        <simple-table :items="items" :fields="fields">
            <template #actions="{item, edit}">
                <span class="text-info cursor-pointer p-2" :title="$t('base.edit').ucFirst()" @click="readDescription(item)">
                    <i class="bx bx-detail"></i>
                </span>
                <span class="text-warning cursor-pointer p-2" :title="$t('base.edit').ucFirst()" @click="editTableItem(item, edit)">
                    <i class="fas fa-edit"></i>
                </span>
                <span class="text-danger cursor-pointer p-2" :title="$t('base.delete').ucFirst()" @click="removeTableItem(item)">
                    <i class="fas fa-trash-alt"></i>
                </span>
            </template>
            <template #default="{close, item}">
                <specialization-form :item="item" fix do-not-save @update="updated => updateTableItem(updated, close)">
                    <template #buttons>
                        <b-button class="ms-2" variant="outline-secondary" @click="close">{{ $t('base.cancel').ucFirst() }}</b-button>
                    </template>
                </specialization-form>
            </template>
            <template #icon="{value}">
                <i v-if="value" :class="value"></i>
            </template>
            <template #color="{value}">
                <span v-if="value" class="cube-20" :style="{background: value}"></span>
            </template>
            <template #is_active="{value}">
                <i v-if="value === true" class="fas fa-check-circle text-success"></i>
                <i v-else class="fas fa-times-circle text-danger"></i>
            </template>
        </simple-table>
        <transition-toggle>
            <specialization-form v-if="showForm" :item="item" fix do-not-save @add="updated => add(updated, close)">
                <template #buttons>
                    <b-button class="ms-2" variant="outline-secondary" @click="close">{{ $t('base.cancel').ucFirst() }}</b-button>
                </template>
            </specialization-form>
        </transition-toggle>
        <transition-toggle>
            <div class="col-12 mb-2 text-center" v-if="!showForm">
                <b-button variant="success" @click="showForm = !showForm">{{ $t('base.add_new').ucFirst() }}</b-button>
            </div>
        </transition-toggle>
        <b-modal v-model="showModal" :title="title" hide-footer>
            <h6>{{ subtitle }}</h6>
            {{ content }}
        </b-modal>
    </b-overlay>
</template>
<style scoped>
.cube-20 {
    display: inline-block;
    width: 20px;
    aspect-ratio: 1 / 1;
}
</style>
