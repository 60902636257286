<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ title }}</h4>
                </div>
                <div class="card-body">
                    <org-position-form :item="position">
                        <template #buttons="{save}">
                            <callback-button
                                class="ms-2"
                                :method="save"
                                :callback="back"
                            ></callback-button>
                        </template>
                    </org-position-form>
                </div>
            </div>
        </div>
        <div class="col-lg-12" v-if="position?.id">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $tc('organizations::specializations.specialization', 2).ucFirst() }}</h4>
                </div>
                <div class="card-body bg-light">
                    <specialization-manager
                        :position-id="position?.id"
                    ></specialization-manager>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>

<script>

import {mapGetters} from "vuex";
import CallbackButton from "@/components/elements/callback-button.vue";
import OrgPositionForm from "../../../../components/forms/org-position-form.vue";
import SpecializationManager from "../../../../components/elements/specialization-manager.vue";

export default {
    components: {SpecializationManager, OrgPositionForm, CallbackButton},

    computed: {
        ...mapGetters({
            position: 'orgPosition/item'
        }),

        title: function () {
            return this.position?.id ? this.$tc('organizations::positions.position', 2).ucFirst() : this.$t('base.create_new_item', {item: this.$tc('organizations::positions.position', 1)}).ucFirst()
        },

        link: function () {
            const path = '/master-data/organizations/positions';
            return {path: this.$t('routes.' + path)}
        },
    },

    methods: {
        back: function () {
            this.$router.push(this.link)
        }
    },

    watch: {
        title: function (value) {
            this.$store.dispatch('app/title', {key: value})
        },
    },

    mounted() {
        if (this.$route.params.id) {
            this.$store.dispatch('orgPosition/show', {id: this.$route.params.id})
        }
    },

    unmounted() {
        this.$store.dispatch('position/clearItem')
        this.$store.dispatch('app/title', '')
    }

}
</script>

<style scoped>

</style>
